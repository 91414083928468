import React, { Component, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { history } from "../src/_helpers";
import Plugin from "./components/Plugin";

import PrivateRoute from "./components/privateRoute";
// import Navbar from "./components/Navbar";

import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile/index";
import Home from "./components/home";

import DefaultHome from "./pages/home/home-page";
import PageNotFound from "./pages/404/404-page";

import ListProperty from "./components/property/List";
import AddProperty from "./components/property/add";
import EditProperty from "./components/property/edit";
import UpdateDraftProperty from "./components/property/updateDraft";

import ListReservation from "./components/reservation/list";
import ReservationDetails from "./components/reservation/details";

import Setting from "./components/setting";
import Faqs from "./components/Faq";
import Contact from "./components/Contact";
import Message from "./components/Message/index";

import Notification from "./components/Notification";

import ReviewList from "./components/reviews/list";

import DisputeList from "./components/dispute";
import DisputeDetails from "./components/dispute/details";

import ListTransaction from "./components/transaction/transactionList";
import ListRefund from "./components/transaction/refundList";

import ReAuth from "./components/reauth";
import Return from "./components/return";

import "./App.css";
import "./styles/main.css";
import Report from "./components/Report";
import Earnings from "./components/earnings/Earnings";
import UpdatePaymentInfo from "./components/updatePaymentInfo/UpdatePaymentInfo";
import CustomHomeDashboard from "./components/custom_dashboard/home";
import CustomPaymentsDashboard from "./components/custom_dashboard/payments";
import CustomBalancesDashboard from "./components/custom_dashboard/balances";
import TransactionDetail from "./components/custom_dashboard/TransactionDetail";
import Dashboard from "./components/Dashboard";
import MaintenanceMode from "./components/MaintenanceMode";
import { siteSettings } from "./_services/general.service";

export default function App({ currentUser, checkUserSession }) {
  const [settings, setSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    siteSettings()
      .then((data) => {
        console.log(data.data.data);
        setSettings(data.data.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }, []);

  if (settings && settings.maintenanceModeHost) {
    return <MaintenanceMode />;
  }
  if(isLoading){
    return <></>
  }

  return (
    <Router history={history}>
      <Plugin />
      <Switch>
        {/* <Route exact path="/register" component={Register} /> */}
        <Route exact path="/" component={DefaultHome} />
        {/* <Route exact path="/" component={Login} /> */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/home" component={Home} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/setting" component={Setting} />
        <PrivateRoute path="/faq" component={Faqs} />
        <PrivateRoute path="/contact" component={Contact} />
        {/* <PrivateRoute path="/report" component={Report} /> */}
        <PrivateRoute path="/message" component={Message} />
        <PrivateRoute path="/notification" component={Notification} />

        <PrivateRoute path="/refund/list" component={ListRefund} />
        <PrivateRoute path="/transaction/list" component={ListTransaction} />

        <PrivateRoute path="/dispute/list" component={DisputeList} />
        <PrivateRoute path="/dispute/details/:id" component={DisputeDetails} />

        <PrivateRoute path="/review" component={ReviewList} />

        <PrivateRoute exact path="/properties" component={ListProperty} />
        <PrivateRoute exact path="/properties/add" component={AddProperty} />
        <PrivateRoute
          exact
          path="/properties/add/:id"
          component={UpdateDraftProperty}
        />
        <PrivateRoute
          exact
          path="/properties/edit/:id"
          component={EditProperty}
        />

        <PrivateRoute
          exact
          path="/settings/account_connect"
          component={UpdatePaymentInfo}
        />

        <PrivateRoute exact path="/earnings" component={Earnings} />
        <PrivateRoute exact path="/custom/dashboard" component={Dashboard} />

        <PrivateRoute
          exact
          path="/custom/home"
          component={CustomHomeDashboard}
        />
        <PrivateRoute
          exact
          path="/custom/payments"
          component={CustomPaymentsDashboard}
        />
        <PrivateRoute
          exact
          path="/custom/payments/:transactionId"
          component={TransactionDetail}
        />

        <PrivateRoute
          exact
          path="/custom/balances"
          component={CustomBalancesDashboard}
        />

        <PrivateRoute exact path="/reservation" component={ListReservation} />
        <PrivateRoute path="/reservation/:id" component={ReservationDetails} />

        <Route exact path="/reauth/:id" component={ReAuth} />
        <Route exact path="/return/:id" component={Return} />

        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
}
