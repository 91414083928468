import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { MdSpaceDashboard } from "react-icons/md";
import loadjs from "loadjs";

import { logout, getToken } from "../_helpers";

class Landing extends Component {
  componentWillMount() {
    loadjs(
      [
        "/vendor/global/global.min.js",
        "/vendor/jquery-nice-select/js/jquery.nice-select.min.js",
        "/js/custom.min.js",
        "/js/deznav-init.js",
        "/vendor/bootstrap/js/bootstrap.bundle.min.js",
      ],
      {
        async: false,
      }
    );
  }

  render() {
    const loginRegLink = (
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/login" className="nav-link">
            Login
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/register" className="nav-link">
            Register
          </Link>
        </li>
      </ul>
    );

    const userLink = (
      <ul className="metismenu" id="menu">
        <li>
          <a className="has-arrow ai-icon" aria-expanded="false">
            <i className="fa fa-house-user"></i>
            <span className="nav-text">Properties</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <Link to="/properties"> Property List </Link>
            </li>
            <li>
              <Link to="/properties/add"> Add Property </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/reservation">
            <i className="flaticon-381-list"></i>
            <span className="nav-text">Reservation </span>
          </Link>
        </li>
        {/* <li>
          <a className="has-arrow ai-icon" aria-expanded="false">
            <i className="fa fa-coins"></i>
            <span className="nav-text">Transaction</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <Link to="/transaction/list"> Transaction List </Link>
            </li>
            <li>
              <Link to="/refund/list"> Refund List </Link>
            </li>
          </ul>
        </li> */}
        <li>
          <Link to="/custom/dashboard">
            <i className="fa fa-bell"></i>
            <span className="nav-text">Dashboard </span>
          </Link>
        </li>

        <li>
          <a className="has-arrow ai-icon" aria-expanded="false">
            <i className="fa fa-balance-scale"></i>
            <span className="nav-text">Dispute </span>
          </a>
          <ul aria-expanded="false">
            <li>
              <Link to="/dispute/list"> Dispute List </Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/review">
            <i className="fa fa-star"></i>
            <span className="nav-text">Review </span>
          </Link>
        </li>
        <li>
          <Link to="/message">
            <i className="fa fa-comment"></i>
            <span className="nav-text">Message </span>
          </Link>
        </li>
        {/* <li>
          <Link to="/earnings">
            <i className="fa fa-bell"></i>
            <span className="nav-text">Earnings </span>
          </Link>
        </li> */}
        {/* <li>
          <Link to="/notification">
            <i className="fa fa-bell"></i>
            <span className="nav-text">Notification </span>
          </Link>
        </li> */}
        <li>
          <Link to="/profile">
            <i className="fa fa-user"></i>
            <span className="nav-text">Profile </span>
          </Link>
        </li>

        <li>
          <Link to="/setting/payout-method">
            <i className="fa fa-credit-card"></i>
            <span className="nav-text">Payout Method</span>
          </Link>
        </li>
        <li>
          <Link to="/faq">
            <i className="flaticon-050-info"></i>
            <span className="nav-text">FAQ </span>
          </Link>
        </li>

        <li>
          <Link to="/contact">
            <i className="flaticon-381-smartphone-5"></i>
            <span className="nav-text">Contact </span>
          </Link>
        </li>
      </ul>
    );

    return (
      <div className="deznav-scroll">
        {getToken() ? userLink : loginRegLink}
      </div>
    );
  }
}

export default withRouter(Landing);
